.react-bootstrap-table {
  max-height: calc(100vh - 220px);
  position: relative;
  z-index: 1;
  margin: auto;
  margin-bottom: 1rem;
  overflow: auto;
  min-height: 370px;

  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }

  tbody {
    overflow-y: auto;
  }

  th,
  td {
    padding: 5px 10px;
    vertical-align: top !important;
  }

  thead {
    position: relative;
    z-index: 1;
    th {
      position: sticky;
      top: 0;
    }
  }

  .filter-label {
    margin-top: 0.5rem;
    display: block;
  }
}
