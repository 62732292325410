@import "../../variables.scss";

.menu {
  .log-out {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

.menu-web {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  overflow: hidden;

  a {
    color: $grey;
    text-align: center;
    cursor: pointer;
  }

  .menu-router {
    font-size: 16px;
    font-family: Roboto Medium;
  }

  .menu-router {
    a {
      padding: 1.5rem !important;

      &:hover:not(.active) {
        background-color: $light-grey;
        text-decoration: none;
        color: $grey;
        transition: all 0.5s;
      }

      &.active {
        color: $white !important;
        background-color: $blue;
        text-decoration: none;
      }
    }
  }

  .header-action {
    .user-icon {
      svg path {
        fill: $grey;
      }
      &:hover {
        background: $light-grey;
      }

      &.active {
        background: $blue;

        svg path {
          fill: $white !important;
        }
      }
    }
  }
}

.menu-mobile {
  display: none;

  .menu-icon {
    height: 55px;
    width: 55px;
    display: none;
    color: $grey;
  }

  &__no-login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;

    .logo {
      margin-left: 0;
    }
  }
}

.profile-menu {
  position: absolute;
  z-index: 2;
  top: 80px;
  right: 0.5rem;
  width: 250px;

  .box-item {
    background: $white;
    box-shadow: $box-shadow;

    > a {
      text-decoration: unset;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.menu-sidebar {
  width: 300px;
  background-color: $white;
  position: fixed !important;
  z-index: 5;
  overflow: auto;
  transition: 1s;

  > a {
    color: $grey;
    border-bottom: 1px solid $light-grey;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    a {
      display: block;
      color: $grey;
      text-align: left;
      padding: 14px 16px;
      text-decoration: none;
    }
  }

  .log-out {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $light-grey;
  }
}

.menu-sidebar-opacity {
  height: 100%;
  width: 100%;
  background-color: $grey;
  position: fixed !important;
  z-index: 4;
  overflow: auto;
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0.7;
}
