@media screen and (min-width: 480px) {
}

@media screen and (max-width: 900px) {
  .menu-web {
    display: none !important;
  }

  .menu-mobile {
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    &__no-login {
      height: 55px !important;
      padding-top: 15px;
    }
    .logo {
      margin-left: 0;
      font-size: 17px;
      font-family: Roboto Medium;
    }
  }

  .app-header {
    height: 55px !important;
  }

  .app {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1100px) {
  .app-header {
    .logo {
      margin-left: 10px;
    }
  }
}
