.Farmer {
    background-color: transparent;

    .Excel {
        color: green;
        height: 15px;
        width: 20px;
    }

    .react-bootstrap-table table>tbody>tr {
        td:nth-child(-n+3) {
            background-color: #fff;
            position: sticky;
            position: -webkit-sticky;
            left: 0;
            z-index: 0;
        }

        td:nth-child(2) {
            left: 60px;
        }

        td:nth-child(3) {
            left: 260px;
        }
    }

    .react-bootstrap-table table>thead>tr:nth-child(1) {
        th:nth-child(-n+3) {
            position: sticky;
            position: -webkit-sticky;
            left: 0;
            z-index: 1;
        }

        th:nth-child(2) {
            left: 60px;
        }

        th:nth-child(3) {
            left: 260px;
        }
    }
}