$white: #ffffff;
$grey: #6f7780;
$blue: #009ada;
$light-grey: #ddd;
$dark-grey: #3a3c3e;
$red: #a94442;
$background: #fafafa;
$header-background: #edf1f5;
$box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

$theme-colors: (
  primary: $blue,
);
